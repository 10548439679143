import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper, PathDesktop, PathMobile } from './styles';

const Icon = ({ isOpen }) => (
  <Container>
    <Wrapper $isOpen={isOpen}>
      <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%">
        <g fill="none" fillRule="evenodd" stroke="#000">
          <PathDesktop strokeWidth="2" d="M0 0L9 11 18 0" />
          <PathMobile strokeWidth="1" d="M0 0L8 9.5 16 0" />
        </g>
      </svg>
    </Wrapper>
  </Container>
);

Icon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default Icon;
