/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useObserver } from '@/hooks';

import Students from './Students';
import Donators from './Donators';
import DropDown from './DropDown';
import { Title, Text, FullScreenWrapper } from './styles';

const Community = () => {
  const { contentJson } = useStaticQuery(
    graphql`
      query {
        contentJson(community: { id: { eq: "community" } }) {
          community {
            committee {
              title
              description
            }
          }
        }
      }
    `
  );
  const { committee } = contentJson.community;

  const { setPosition } = useObserver();

  return (
    <>
      <Title onTouchStart={setPosition} onMouseEnter={setPosition}>
        Wir fördern Chancengleichheit im Medizinstudium für angehende und aktive Student/innen. Der Zugang zum Medizinstudium und
        auch der spätere Erfolg in der Ausbildung und im Beruf hängt noch immer massgeblich von der Herkunft eines jungen
        Erwachsenen ab – nicht nur von seiner Begabung. Mit dem Verein für Chancengleichheit im Medizinstudium möchten wir einen
        Beitrag leisten, um gezielt begabte und engagierte Student/innen mit schwierigen Startbedingungen zu fördern. Mit unserem
        Engagement möchten wir jungen Erwachsenen eine Karriere in der Medizin eröffnen, die ihnen aufgrund ihrer Herkunft ohne
        Unterstützung oftmals verwehrt geblieben wäre.
      </Title>
      <FullScreenWrapper bt>
        <Students />
      </FullScreenWrapper>
      <Text onTouchStart={setPosition} onMouseEnter={setPosition}>
        Der nicht-gewinnorientierte Verein appertus stellt die schweizweit führende Vorbereitungs-App für den Eignungstest für das
        Medizinstudium (EMS, «Numerus Clausus») kostenlos zur Verfügung. EMS-Vorbereitungskurse sind sehr teuer und bleiben heute
        den privilegierten Anwärter/innen vorbehalten. Die appertus-App ist unser Beitrag, allen Anwärter/innen eine
        Vorbereitungsmöglichkeit auf den anspruchsvollen EMS zu bieten. Unser Verein befähigt junge Menschen im Medizinstudium,
        Eigenverantwortung für ihre Karriere zu übernehmen. Dazu beraten wir Medizinstudentin/innen kostenlos in ihrer
        Karriereplanung, geben ihnen Zugang zu unserem Netzwerk von anerkannten Kliniker/innen, involvieren sie in klinische
        Forschungsprojekte und mehr.
      </Text>
      <Donators />
      <Text onTouchStart={setPosition} onMouseEnter={setPosition}>
        Vorstand
      </Text>
      <FullScreenWrapper>
        {committee.map(({ title, description }, key) => (
          <DropDown key={key} title={title}>
            {description}
          </DropDown>
        ))}
      </FullScreenWrapper>
    </>
  );
};

export default Community;
