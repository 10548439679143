/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { pipe, props, filter, join, when, not } from 'ramda';

import { useObserver } from '@/hooks';
import { useDonators } from '@/server-api/hooks';
import { ID, MEMBERSHIP, FIRST_NAME, LAST_NAME, COMPANY, LIMIT } from '@/server-api/consts';

import { Container, P, Divider, Wrapper, Item, Title, Text } from './styles';

const params = { [LIMIT]: 18 };

const Donators = () => {
  const { setPosition } = useObserver();
  const { success } = useDonators(params);
  const list = success?.data;

  const getName = useCallback(
    (item = {}) =>
      pipe(
        props([FIRST_NAME, LAST_NAME]),
        filter(Boolean),
        join(' '),
        when(not, () => 'N/A')
      )(item),
    []
  );

  return (
    <Container $isShow={list && list.length} onTouchStart={setPosition} onMouseEnter={setPosition}>
      <P>Gönner/innen</P>
      <Divider />
      <P>Wir danken unseren Gönner/innen, die uns beim Verfolgen unserer Ziele so grosszügig unterstützen.</P>
      <Wrapper>
        {list &&
          list.map((item) => (
            <Item key={item[ID]}>
              <Title>{item[MEMBERSHIP] || 'N/A'}</Title>
              <Text>{getName(item)}</Text>
              <Text>{item[COMPANY] || 'N/A'}</Text>
            </Item>
          ))}
      </Wrapper>
    </Container>
  );
};

export default Donators;
