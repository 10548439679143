import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fonts, palette } from '@/theme';

const { black, white, red, gray } = palette;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 3px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${black};
  @media (max-width: 991px) {
    min-height: 30px;
  }
`;

export const Title = styled.p`
  flex: 1 1 auto;
  ${fonts.text}
  text-align: left;
  color: ${white};
  padding: 0 15px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 0 8px;
  }
`;

export const Content = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    height: $isOpen ? 'auto' : 0,
    overflow: $isOpen ? 'visible' : 'hidden',
  },
  transition: {
    height: { duration: 0.5 },
    overflow: { delay: $isOpen ? 0.5 : 0, duration: 0 },
  },
}))`
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 62px;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: calc(100% + 3px);
    width: 60px;
  }
  &:before {
    left: 0;
    background-color: ${red};
  }
  &:after {
    right: 0;
    background-color: ${gray};
  }
  @media (max-width: 991px) {
    padding: 0 16px;
    &:before,
    &:after {
      width: 15px;
    }
  }
`;

export const Text = styled.p`
  ${fonts.text}
  padding: 10px 20px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 6px 10px;
  }
`;
