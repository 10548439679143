import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import { Container, Button, Title, Content, Text } from './styles';

const DropDown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  return (
    <Container>
      <Button type="button" onClick={handleClick}>
        <Title>{title}</Title>
        <Icon isOpen={isOpen} />
      </Button>
      <Content $isOpen={isOpen}>
        <Text>{children}</Text>
      </Content>
    </Container>
  );
};

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default DropDown;
