import styled from 'styled-components';

import { fonts, palette } from '@/theme';

export const Title = styled.p`
  ${fonts.lead}
  padding: 10px 20px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 6px 10px;
  }
`;

export const Text = styled.p`
  ${fonts.text}
  padding: 10px 20px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 6px 10px;
  }
`;

export const FullScreenWrapper = styled.div`
  position: relative;
  top: 0;
  left: -62px;
  width: calc(100% + 2 * 62px);
  ${({ bt }) => bt && 'border-top: 2px solid'};
  border-color: ${palette.black};
  @media (max-width: 991px) {
    left: -16px;
    width: calc(100% + 2 * 16px);
    border-width: 1px;
  }
`;
