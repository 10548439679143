import styled from 'styled-components';
import { motion } from 'framer-motion';

import { palette } from '@/theme';

export const Container = styled(motion.div).attrs(() => ({
  animate: { height: 'auto', overflow: 'visible' },
  transition: {
    height: { duration: 0.5 },
    overflow: { delay: 0.5 },
  },
}))`
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: ${palette.white};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;

  border: 2px solid ${palette.black};
  border-top: none;
  @media (min-width: 1441px) {
    border-right: none;
    border-left: none;
  }
  @media (max-width: 991px) {
    border-width: 1px;
  }
`;
