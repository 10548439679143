export const SORT_BY = 'sortBy';
export const SORT_DIR = 'sortDir';
export const START_AFTER = 'startAfter';
export const LIMIT = 'limit';
export const EXPAND = 'expand[]';
export const FIELDS = 'fields[]';

export const COUNT = 'count';
export const HAS_MORE = 'hasMore';
export const ASC = 'asc';
export const DESC = 'desc';

export const CREATED = 'createdAt';
export const MODIFIED = 'modifiedAt';

export const ID = '_id';
export const KIND = 'kind';
export const NAME = 'name';
export const COMPANY = 'company';
export const TITLE = 'title';
export const EMAIL = 'email';
export const MESSAGE = 'message';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const ADDRESS = 'address';
export const ZIP_CODE = 'zipCode';
export const CITY = 'city';
export const PUBLISH = 'publish';
export const MEMBERSHIP = 'membership';
export const AGE = 'age';
export const IMG = 'img';
export const SCHOOL = 'school';

export const PAYMENT = 'payment';
export const AMOUNT = 'amount';
export const CURRENCY = 'currency';
export const PAYMENT_METHOD = 'paymentMethod';
export const INVOICE = 'invoice';
export const CARD = 'card';

export const MEMBERSHIP_TYPES = {
  BASIC: 'basic',
  GOLD: 'gold',
  CLUB: 'club',
};
