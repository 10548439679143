import styled from 'styled-components';
import { motion } from 'framer-motion';

import { palette } from '@/theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-top: 2px solid ${palette.black};
  border-bottom: 2px solid ${palette.black};
  background-color: ${palette.white};
  @media (max-width: 991px) {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-width: 1px;
  }
`;

export const Wrapper = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    scale: [1, 0, 1],
    rotate: $isOpen ? 180 : 0,
  },
  transition: {
    scale: { duration: 0.5 },
    rotate: { duration: 0, delay: 0.25 },
  },
}))`
  height: 15px;
  width: 18px;
  transform: scale(1) rotate(0);
  @media (max-width: 991px) {
    width: 16px;
  }
`;

export const PathDesktop = styled.path`
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const PathMobile = styled.path`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;
