import styled from 'styled-components';

import { fonts, palette } from '@/theme';

const { black, white } = palette;

export const Container = styled.div`
  flex: 1 1 auto;
  position: relative;
  max-width: calc(100% / ${({ amount }) => amount} + 0.5px);
  padding-top: calc((100% / ${({ amount }) => amount}) * 3 / 2);
  border-right: 2px solid ${black};
  &:last-child {
    border-right: none;
  }
  @media (max-width: 991px) {
    border-width: 1px;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  &,
  & > * {
    transition: all 0.5s;
  }
`;

export const Img = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  ${Wrapper}:active &, ${Wrapper}:hover & {
    top: -100%;
  }
`;

export const DescriptionWrap = styled.div`
  position: absolute;
  top: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ reverse }) => (reverse ? black : white)};
  color: ${({ reverse }) => (reverse ? white : black)};
  border-color: ${({ reverse }) => (reverse ? white : black)};
  ${Wrapper}:active &, ${Wrapper}:hover & {
    top: 0;
  }
`;

export const Text = styled.p`
  ${fonts.smallText13}
  border: 2px solid;
  border-left: none;
  border-right: none;
  color: inherit;
  border-color: inherit;
  padding: 1px 4px 2px 4px;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
`;
