/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { times } from 'ramda';

import { useStudents } from '@/server-api/hooks';
import { ID, LIMIT } from '@/server-api/consts';

import Image from './Image';
import { Container, Row } from './styles';

const params = { [LIMIT]: 18 };

const Students = () => {
  const { success } = useStudents(params);
  const list = success?.data;
  const [imageMap, setImageMap] = useState([0, 0]);
  const defineImageMap = useCallback(() => {
    const width = window?.innerWidth;

    if (!width) return;

    const isMobile = width <= 991;
    const amount = Math.round(Math.min(1440, width) / (isMobile ? 125 : 240));
    const rows = isMobile ? 4 : 2;

    setImageMap(($) => ($[0] === amount && $[1] === rows ? $ : [amount, rows]));
  }, []);

  const studentsMap = useMemo(() => {
    if (!list?.length) return [[]];

    const [amount, rows] = imageMap;

    return times((index) => list.slice(index * amount, index * amount + amount), rows);
  }, [imageMap, list]);

  useEffect(() => {
    if (typeof window === 'undefined') return () => null;
    defineImageMap();
    window.addEventListener('resize', defineImageMap);

    return () => {
      window.removeEventListener('resize', defineImageMap);
    };
  }, [defineImageMap]);

  if (!list?.length) return null;

  return (
    <Container>
      {studentsMap.map((row, key) => (
        <Row key={key}>
          {row.map((item, index) => (
            <Image key={item[ID]} student={item} amount={imageMap[0]} reverse={Boolean((key + index) % 2)} />
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Students;
