import React from 'react';
import PropTypes from 'prop-types';

import { IMG, FIRST_NAME, AGE, SCHOOL } from '@/server-api/consts';

import { Container, Wrapper, Img, DescriptionWrap, Text } from './styles';

const Image = ({ student, amount, reverse }) => (
  <Container amount={amount}>
    <Wrapper>
      <Img url={student[IMG]} />
      <DescriptionWrap reverse={reverse}>
        <Text>{student[FIRST_NAME]}</Text>
        <Text>{`${student[AGE]} Jahre`}</Text>
        <Text>{student[SCHOOL]}</Text>
      </DescriptionWrap>
    </Wrapper>
  </Container>
);

Image.propTypes = {
  student: PropTypes.shape({
    [IMG]: PropTypes.string.isRequired,
    [FIRST_NAME]: PropTypes.string.isRequired,
    [AGE]: PropTypes.number.isRequired,
    [SCHOOL]: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  reverse: PropTypes.bool.isRequired,
};

export default Image;
