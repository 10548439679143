import styled from 'styled-components';
import { motion } from 'framer-motion';

import { palette, fonts } from '@/theme';

const { black, white, gray } = palette;

export const Container = styled(motion.div).attrs(({ $isShow }) => ({
  animate: { height: $isShow ? 'auto' : 0 },
  transition: { duration: 0.5 },
}))`
  position: relative;
  top: 0;
  left: -62px;
  width: calc(100% + 2 * 62px);
  border-top: 2px solid ${palette.black};
  height: 0;
  overflow: hidden;
  @media (max-width: 991px) {
    left: -16px;
    width: calc(100% + 2 * 16px);
    border-width: 1px;
  }
`;

export const P = styled.p`
  ${fonts.text}
  padding: 10px 82px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 6px 26px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 2px solid ${black};
  @media (max-width: 991px) {
    border-width: 1px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${white};
  box-shadow: inset 0 -2px 0 0;
  border-top: 2px solid ${black};
  @media (max-width: 991px) {
    box-shadow: inset 0 -1px 0 0;
    border-width: 1px;
  }
`;

export const Item = styled.div`
  width: calc(100% / 6);
  & > p {
    border-right: 2px solid ${black};
  }
  &:nth-child(6n) > p {
    border-right: none;
  }
  @media (max-width: 1199px) {
    width: calc(100% / 5);
    & > p:nth-child(n) {
      border-right: 2px solid ${black};
    }
    &:nth-child(5n) > p {
      border-right: none;
    }
  }
  @media (max-width: 991px) {
    width: calc(100% / 4);
    & > p:nth-child(n + 1) {
      border-right: 1px solid ${black};
    }
    & > p:first-child {
      border-right: 1px solid ${white};
    }
    &:nth-child(4n) > p {
      border-right: none;
    }
    border-width: 1px;
  }
  @media (max-width: 799px) {
    width: calc(100% / 3);
    & > p:nth-child(n + 1) {
      border-right: 1px solid ${black};
    }
    & > p:first-child {
      border-right: 1px solid ${white};
    }
    &:nth-child(3n) > p {
      border-right: none;
    }
  }
  @media (max-width: 599px) {
    width: calc(100% / 2);
    & > p:nth-child(n + 1) {
      border-right: 1px solid ${black};
    }
    & > p:first-child {
      border-right: 1px solid ${white};
    }
    &:nth-child(2n) > p {
      border-right: none;
    }
  }
`;

export const Text = styled.p`
  ${fonts.smallText13}
  font-size: 14px;
  background-color: ${white};
  border-bottom: 2px solid ${black};
  padding: 2px 4px;
  @media (max-width: 991px) {
    font-size: 13px;
    border-width: 1px;
  }
`;

export const Title = styled(Text)`
  background-color: ${gray};
  &::first-letter {
    text-transform: uppercase;
  }
  @media (max-width: 991px) {
    background-color: ${black};
    color: ${white};
    border-width: 1px;
  }
`;
